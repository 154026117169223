@import '@/styles/mixins.less';

html,
body {
  font-family: PingFangSC-Regular, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background: white;
  .normalize();
}

#root {
  .size(100%);
}

.line-clamp {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 !important;
}

.line-clamp:after {
  text-align: right;
  bottom: 0;
  right: 0;
  width: 5%;
  display: block;
  position: absolute;
  height: calc(1em * 1.2);
}

@supports (-webkit-line-clamp: 1) {
  .line-clamp:after {
    display: none !important;
  }
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  height: calc(1em * 1.2 * 2);
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  height: calc(1em * 1.2 * 1);
}
@primary-color: #3872E0;@text-color: #212B36;