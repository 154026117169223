@import (reference) '@/styles/mixins.less';

.ame-button {
  background: #F6FAFF;
  border: 1px solid #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  color: #2C2C2C;
  line-height: 17px;
  border-radius: 8px;
  .clickable();

  &:hover {
    background: #F2F7FD;
  }
}

.ame-button.ame-button-primary {
  border: none;
  background: linear-gradient(129deg, #362FFF 0%, #5651F7 100%);
  font-weight: 600;
  color: #FFFFFF;

  &:hover {
    background: linear-gradient(129deg, #342EF5 0%, #524DED 100%);
  }
}

.ame-button.ame-button-link {
  border: none;
  color: #3E37FD;
  background: none;
  padding: 2px 4px;
  height: auto;
  .clickable();

  &:hover {
    color: #3932E9 !important;
  }
}
@primary-color: #3872E0;@text-color: #212B36;