@import (reference) '../../styles/mixins.less';

.ame-spin {
  &.fullscreen {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
@primary-color: #3872E0;@text-color: #212B36;