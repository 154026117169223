@import (reference) '../../styles/mixins.less';

.common-dialog-layout {
  .flex-col();
  justify-content: flex-start;

  .free-vip-no-count-modal {
    :global {
      .ant-modal-content {
        padding-top: 24px;
        padding-bottom: 32px;
      }

      .ant-modal-title {
        text-align: center;
        margin-bottom: 20px;
      }

      .ant-modal-body {
        .flex-col();
        justify-content: flex-start;
      }
    }

    .banner {
      .size(143px, 131px);
    }

    .desc {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
      margin-top: 30px;
    }

    .confirm-btn {
      width: 204px;
      height: 40px;
      border-radius: 8px;
      margin-top: 16px;
    }
  }

  .contact-modal {
    .flex-col();
    justify-content: flex-start;

    :global {
      .ant-modal-content {
        padding-top: 24px;
        padding-bottom: 32px;
      }

      .ant-modal-title {
        text-align: center;
        margin-bottom: 24px;
      }

      .ant-modal-body {
        .flex-col();
        justify-content: flex-start;
      }
    }

    .qr-code {
      .size(182px);
    }

    .desc {
      margin-top: 24px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
    }
  }

  .version-modal {
    :global {
      .ant-modal-body {
        .flex-col();
        padding-top: 24px 33px 32px 33px;
      }

      .ant-modal-close {
        display: none;
      }
    }

    .title {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 22px;
    }

    .version-img {
      margin-top: 19px;
      .size(153px,131px);
    }

    .desc {
      width: 230px;
      height: 34px;
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
      text-align: center;
      margin-top: 32px;
    }

    .btn {
      width: 204px;
      height: 40px;
      background: linear-gradient(129deg, #362fff 0%, #5651f7 100%);
      border-radius: 8px;
      margin-top: 16px;
    }
  }

  .upgrade-modal {
    :global {
      .ant-modal-body {
        .flex-col();
        padding-top: 24px 0 16px 0;
      }
    }

    .title {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 22px;
    }

    .version-img {
      margin-top: 27px;
      .size(149px,125px);
    }

    .desc {
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
      margin-top: 29px;
    }

    .btn {
      width: 204px;
      height: 40px;
      background: linear-gradient(129deg, #362fff 0%, #5651f7 100%);
      border-radius: 8px;
      margin-top: 16px;

      &:hover {
        opacity: 0.9;
      }
    }

    .cancel {
      width: 28px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #3e37fd;
      line-height: 20px;
      margin-top: 16px;
      .clickable();

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

@primary-color: #3872E0;@text-color: #212B36;